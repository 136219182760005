import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import moment from "moment";
import global_function from "./global/global_function.js";
import "./global/commonStyle.css";
import filters from "./helper/filters.js";
import axios from "axios";
import { format, isThisSecond, parseISO } from "date-fns";
Vue.config.productionTip = false;
import VueExcelXlsx from "vue-excel-xlsx";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import DeleteConfirmation from "./components/DeleteConfirmation.vue";
import AlertPopup from "./components/AlertPopup.vue";
import OverlayLoader from "./components/OverlayLoader.vue";
import { api, public_api } from "./services/api.js";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueGeolocation from "vue-browser-geolocation";
import "vue-good-table/dist/vue-good-table.css";
Vue.use(VueGeolocation);

// import JsPDFAutotable from 'jspdf-autotable'
// Vue.use(JsPDFAutotable)

import VueHtml2pdf from "vue-html2pdf";
Vue.use(VueHtml2pdf);
// Vue.use(VueHtml2pdf);
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: "AIzaSyAk5_KenWtn7vxeoviqq5c_Zu_kF1dYnB0",
});

Vue.use(Toast, {
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 4,
  newestOnTop: true,
});

Vue.use(VueExcelXlsx);
Vue.moment = moment;
Vue.prototype.$moment = moment;
Vue.prototype.$api = api;
Vue.prototype.$papi = public_api;
Vue.prototype.$imgurl_original =
  "https://devcdn.tectalik.com/compliance/uploads/original_";

Vue.component("DeleteConfirmation", DeleteConfirmation);
Vue.component("AlertPopup", AlertPopup);
Vue.component("OverlayLoader", OverlayLoader);

//Vue.use(moment)
Vue.moment = moment;

//get domain here
// let getdomain = window.location.host.split(":");

let api_subdomain = "";
let api_analytics = "";
const node_env = process.env.NODE_ENV.toLowerCase().trim();
if (node_env === "production") {
  Vue.prototype.apiurl = "https://compliance-api.tectalik.com/api/";
  Vue.prototype.$imgurl = "https://compliance-api.tectalik.com/uploads/";
  api_subdomain = "https://compliance-api.tectalik.com/";
  api_analytics = "https://analytics-api.tectalik.com/api/";
} else if (node_env === "development") {
  Vue.prototype.apiurl = "https://compliance-api-staging.tectalik.com/api/";
  Vue.prototype.$imgurl =
    "https://compliance-api-staging.tectalik.com/uploads/";
  api_subdomain = "https://compliance-api-staging.tectalik.com/";
  api_analytics = "https://staging-business-health-api.tectalik.com/api/";

  // Vue.prototype.apiurl = "http://localhost:4001/api/";
  // Vue.prototype.$imgurl = "http://localhost:4001/uploads/";
  // api_subdomain = "http://localhost:4001/";
} else if (node_env === "lan") {
  api_subdomain = "http://192.168.0.120:4001/";
  Vue.prototype.apiurl = api_subdomain + "api/";
  Vue.prototype.$imgurl = api_subdomain + "uploads/";
} else if (node_env == "lan_secure") {
  api_subdomain = "https://192.168.0.120:4001/";
  Vue.prototype.apiurl = api_subdomain + "api/";
  Vue.prototype.$imgurl = api_subdomain + "uploads/";
} else {
  Vue.prototype.apiurl = "http://localhost:4001/api/";
  Vue.prototype.$imgurl = "http://localhost:4001/uploads/";
  api_subdomain = "http://localhost:4001/";
}
console.log(`${node_env} =  environment`);

router.beforeEach((to, from, next) => {
  var authRequired = to.meta.auth;
  var res_ans = to.name;
  console.log(res_ans, "= res_ans");
  var token = localStorage.getItem("TOKEN");
  var res_ans = to.name;
console.log()
  if (res_ans === "health_check_answer" || res_ans==="finance_health_questions" || res_ans==="health_check_dashboard" || res_ans==="health_check_home_screen" || res_ans==="completion_screen_health_check" || res_ans === "health_check_dashboard_view") {
    next(); 
    return;
  }
  

  if (!authRequired && token) {
    if (
      res_ans != "survey_answer_employee_result" &&
      res_ans != "survey_answer_customer_result"
    ) {
      // alert(res_ans)
      
      if (product_list.includes("TECTALIK_FMS")) {
        next("/welcome_page");
      } else {
          next("/");
      }
      return;
    }
  }

  if (authRequired) {
    if (!token) {
      next("/login");
      return;
    }
  }
  var features_list = localStorage.getItem("features_list");
  var domain = localStorage.getItem("domain");
  var flags = 0;
  var screen_right_dataset = JSON.parse(
    localStorage.getItem("SCREEN_RIGHT_DATASET")
  );
  var product_get = localStorage.getItem("tectalik_product_list");
  localStorage.setItem("domain", domain);
  let product_list = [];
  if (
    product_get != null &&
    product_get != "" &&
    product_get != undefined &&
    product_get.length != 0
  ) {
    product_list = product_get.split(",");
  }
  var page_flg = false;
  if (domain != "admin") {
    let page_code = "";
    let page_type = "";
    if (to.meta.page_code) {
      page_code = to.meta.page_code;
    }
    if (to.meta.page_type) {
      page_type = to.meta.page_type;
    }
    if (
      screen_right_dataset != null &&
      screen_right_dataset != "" &&
      screen_right_dataset != undefined &&
      screen_right_dataset.length != 0
    ) {
      screen_right_dataset = screen_right_dataset.filter(function(e) {
        return e.screen_code == page_code;
      });
      for (let i = 0; i < screen_right_dataset.length; i++) {
        if (screen_right_dataset[i].screen_code == page_code) {
          page_flg = screen_right_dataset[i][page_type];
          if (page_flg) {
            break;
          }
        }
      }
    }
    // let retun_flag = this.screen_auth(page_code,page_type);
    // to.path == "/login" || to.path == "/show_list" || to.path == "/forgotpassword" || to.path == "/forgotpassword_profile" || to.path == 'appraisal_add'
    if (to.meta.isnoadmintoshow) {
      next();
    } else {
      if (
        page_flg &&
        (res_ans != "survey_answer_customer_result" ||
          res_ans != "survey_answer_employee_result" ||
          page_code == "SCN_SURVEY")  ||
        (res_ans != "health_check_answer" ||
          page_code == "SCN_BUSINESS_HEALTH_CHECK")  ||
        (res_ans != "finance_health_questions" ||
            page_code == "SCN_BUSINESS_HEALTH_CHECK")  ||
            (res_ans != "health_check_dashboard" ||
              page_code == "SCN_BUSINESS_HEALTH_CHECK")  ||
            (res_ans != "health_check_home_screen" ||
              page_code == "SCN_BUSINESS_HEALTH_CHECK")  ||
            (res_ans != "completion_screen_health_check" ||
              page_code == "SCN_BUSINESS_HEALTH_CHECK")  ||
            (res_ans != "health_check_dashboard_view" ||
              page_code == "SCN_BUSINESS_HEALTH_CHECK")  
            
      ) {
        next();
      } else if (
        res_ans == "survey_answer_customer_result" ||
        res_ans == "survey_answer_employee_result" || 
        res_ans == "health_check_answer"||
        res_ans == "finance_health_questions" ||
        res_ans == "health_check_dashboard" ||
        res_ans == "health_check_home_screen" ||
        res_ans == "completion_screen_health_check" ||
        res_ans == "health_check_dashboard_view" 


      ) {
        next();
      } else {
        // next("/show_list");
        if (product_list.includes("TECTALIK_FMS")) {
            next("/welcome_page");
        } else {
            next("/show_list");
        }
      }
    }
  } else {
    // path == "/login" || to.path == "/client" || to.path == "/Industry" || to.path == "/checklist_Template_page" || to.path == "/templatelibrary" || to.path == "/product" || to.path == "/process" || to.path == "/kpiroles" || to.path == "/tkt_service_category"
    if (to.meta.iscommontoshow) {
      next();
    } else {
      // next("/checklist_Template_page");
      if (product_list.includes("TECTALIK_FMS")) {
        next("/welcome_page");
      } else {
          next("/checklist_Template_page");
      }
    }
  }
});

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD-MM-YYYY");
  }
});

Object.keys(filters).forEach((key) => Vue.filter(key, filters[key]));

axios.interceptors.request.use(
  function(config) {
    var url_break = window.location.pathname.split("/");
    if (url_break.length) {
      url_break = url_break[1];
    }

    var url = config.url;
    config.url = "";
    var split_url = url.split("/");
    if (split_url.length) {
      split_url = split_url[0];
    }
    if (
      (url_break == "kpi_bots" &&
        split_url != "entities" &&
        split_url != "getUserRoles") ||
      url == "analytics_users" ||
      url == "analytics_users_add" ||
      url == "analytics_users_edit" ||
      url == "analytics_users_delete" ||
      url == "analytics_users_soft_delete" ||
      url == "analytics_verify_token" ||
      url == "analytics_token_update" ||
      url == "get_collection/data" ||
      url == "update_sync_datas" ||
      url == "form_master_sync_add" ||
      url == "show_kpi"

    ) {
      config.url = api_analytics + url;
      localStorage.setItem("url", config.url);
    } else if (
      url_break == "admin_skills_add" || 
      url_break == "admin_role_assignments_add" ||
      url_break == "admin_desig_landing_add" ||
      url_break == "admin_dept_landing_add" ||
      url_break == "forgotpassword_admin" ||
      url_break == "admin_function_landing_add" ||
      url_break == "library" ||
      url_break == "partner" ||
      url_break == "user_type" ||
      url_break == "common_rights" ||
      url_break == "Common_user" ||
      url_break == "Common_user_edit" ||
      url_break == "Common_user_add" ||
      url_break == "template_process" ||
      url_break == "checklist_template" ||
      url_break == "task_template" ||
      url_break == "subtask_template" ||
      url_break == "client" ||
      url_break == "Industry" ||
      url_break == "product" ||
      url_break == "process" ||
      url_break == "kpiroles" ||
      url_break == "tkt_service_category" ||
      url_break == "image" ||
      url_break == "modules" ||
      url_break == "addmodules" ||
      url_break == "editmodules" ||
      url_break == "addscreen" ||
      url_break == "editscreen" ||
      url_break == "dashboard_landing" ||
      url_break == "Assets" ||
      url_break == "subscription" ||
      url_break == "discount_mstr" ||
      url_break == "lead_landing" ||
      url_break == "lead_add" ||
      url_break == "lead_edit" ||
      url_break == "lead_source_landing" ||
      url_break == "campaign_landing" ||
      url_break == "oro_to_postgres" 
    ) {
      config.url = api_subdomain + "api/" + url;
      localStorage.setItem("url", config.url);
    } else if (
      url_break == "admin_desig_landing" ||
      url_break == "admin_dept_landing" ||
      url_break == "admin_role_assignments_group" ||
      url_break == "admin_role_assignments_landing" ||
      url_break == "admin_skills_landing" ||
      url_break == "admin_skills_group" ||
      url_break == "admin_function_landing" ||
      url_break == "checklist_Template_page" ||
      url_break == "kpi_additions" ||
      url_break == "templatelibrary" ||
      url_break == "checklist_Template_page_test" ||
      url_break == "login" ||
      url_break == "forgotpassword" ||
      url_break == "forgotpassword_profile" ||
      url_break == "appraisal_add"
    ) {
      config.url = api_subdomain + url;
      localStorage.setItem("url", config.url);
    } else {
      config.url = api_subdomain + "tapi/" + url;
      localStorage.setItem("url", config.url);
    }

    var token = localStorage.getItem("TOKEN");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");


if ('serviceWorker' in navigator) {
  // navigator.serviceWorker.register('/firebase-messaging-sw.js', { type: 'module' })
  // // navigator.serviceWorker.register('/firebase-messaging-sw.js')
  //   .then(function(registration) {
  //     console.log('Service Worker registration successful with scope:', registration.scope);
  //   })
  //   .catch(function(err) {
  //     console.log('Service Worker registration failed:', err);
  //   });
  navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/firebase-cloud-messaging-push-scope' })
  .then(function(registration) {
    console.log('Service Worker registered with scope:', registration.scope);
  })
  .catch(function(err) {
    console.error('Service Worker registration failed:', err);
  });
} else {
  console.log('Service Workers are not supported in this browser.');
}

